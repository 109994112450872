<template>
  <div class="searchFormLine">
    <PocSlider minheight="60px" maxHeight="350px" btntype="primary">
      <el-form
        size="mini"
        :inline="true"
        label-position="top"
        class="minWidthInput"
      >
        <el-form-item label="English Name">
          <el-input v-model="ruleForm.englishName" />
        </el-form-item>
        <el-form-item label="Status">
          <el-select v-model="ruleForm.status" clearable placeholder="请选择">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Local Name">
          <el-input v-model="ruleForm.localName" />
        </el-form-item>
        <el-form-item label="Company Name">
          <el-input v-model="ruleForm.companyName" />
        </el-form-item>
        <el-form-item label="HFM Code">
          <el-select
            v-model="ruleForm.hfmCode"
            clearable
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in HFMList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="LM Name">
          <el-input v-model="ruleForm.lmName" />
        </el-form-item>
        <el-form-item label="Title">
          <el-input v-model="ruleForm.title" />
        </el-form-item>
        <el-form-item label="TA Owner">
          <el-input v-model="ruleForm.taOwner" />
        </el-form-item>
        <el-form-item label="Internal Brand">
          <el-input v-model="ruleForm.internalBrand" />
        </el-form-item>
        <el-form-item label="Join Date">
          <el-date-picker
            v-model="ruleForm.joinDate"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            @change="joinDateChange"
          >
          </el-date-picker>
          <!--                   :default-time="['12:00:00']"-->
        </el-form-item>
        <el-form-item label="Leave Date">
          <el-date-picker
            v-model="ruleForm.leaveDate"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
            @change="leaveDateChange"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </PocSlider>
  </div>
</template>

<script>
import pocSlider from '@/components/poc-slider/poc-slider'
import {removeItem} from "@/utils/storage";
import { getHfmCodeList } from "@/api/organization";
export default {
  name: 'searchFormLine',
  props: {
    position: {
      type: String,
      required: true
    }
  },
  components: {
    pocSlider
  },
  watch: {
    position: {
      handler(val) {
        removeItem('internSearchData')
        let obj = {
          forRecruitment: [
            { label: '新建', value: 1 },
            { label: '待招聘', value: 2 },
            { label: '已签回', value: 3 }
          ],
          onTheJob: [
            { label: '已入职', value: 4 },
            { label: '离职申请中', value: 5 },
            { label: '离职待审批', value: 6 },
            { label: '待离职', value: 7 }
          ],
          stayPositive: [],
          haveLeft: [{ label: '已离职', value: 8 }],
          refusedEntry: [{ label: '拒绝入职', value: 9 }]
        }
        if (val) {
          this.statusList = obj[val]
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      ruleForm: {
        englishName: '',
        hfmCode: '',
        status: '',
        localName: '',
        companyName: '',
        lmName: '',
        title: '',
        taOwner: '',
        internalBrand: '',
        joinDate: [],
        leaveDate: [],
        joinDateBegin: '',
        joinDateEnd: '',
        leaveDateBegin: '',
        leaveDateEnd: ''
      },
      HFMList: [],
      statusList: [
        // { label: '新建', value: 1 },
        // { label: '待招聘', value: 2 },
        // { label: '已签回', value: 3 },
        // { label: '已入职', value: 4 },
        // { label: '离职申请中', value: 5 },
        // { label: '离职待审批', value: 6 },
        // { label: '待离职', value: 7 },
        // { label: '已离职', value: 8 },
        // { label: '拒绝入职', value: 9 }
      ],
      visible: false
    }
  },
  created() {
    removeItem('internSearchData')
    this.getHfmCodeList()
  },
  methods: {
    getHfmCodeList(){
      getHfmCodeList().then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.HFMList = res.data
          }
        }
      })
    },
    unfold() {
      this.visible = true
    },
    joinDateChange(val) {
      if (val) {
        this.ruleForm.joinDateBegin = val[0]
        this.ruleForm.joinDateEnd = val[1]
      } else {
        this.ruleForm.joinDateBegin = ''
        this.ruleForm.joinDateEnd = ''
      }
    },
    leaveDateChange(val) {
      if (val) {
        this.ruleForm.leaveDateBegin = val[0]
        this.ruleForm.leaveDateEnd = val[1]
      } else {
        this.ruleForm.leaveDateBegin = ''
        this.ruleForm.leaveDateEnd = ''
      }
    },
    close() {
      this.visible = false
      // this.reset()
    },
    reset() {
      Object.keys(this.ruleForm).forEach((item) => {
        if (item === 'joinDate' || item === 'leaveDate') {
          this.ruleForm[item] = []
        } else {
          this.ruleForm[item] = ''
        }
      })
    },
    search() {
      delete this.ruleForm.leaveDate
      delete this.ruleForm.joinDate
      this.$emit('onSearch', JSON.parse(JSON.stringify(this.ruleForm)))
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.searchFormLine {
}
</style>
