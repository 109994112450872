export const stayPositiveColumn = [
  {
    key: 'idNumber',
    name: 'ID Number',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'localName',
    name: 'Local Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  },
  {
    key: 'englishName',
    name: 'English Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  },
  {
    key: 'lmName',
    name: 'LM Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 120 // 默认宽度，像素
  },
  {
    key: 'title',
    name: 'Title',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  },
  {
    key: 'joinDate',
    name: 'Join Date',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: null // 默认宽度，像素
  }
]
