<template>
  <div class="RoleButton">
    <div v-if="roleStatus === '待招聘'">
      <el-tooltip
        role="deleteInRecruitingIntern"
        v-permission
        class="item"
        effect="dark"
        content="删除"
        placement="top"
      >
        <el-button type="text" @click.native="deleteInRecruiting">
          <i class="svg-icon el-icon-delete"></i>
        </el-button>
      </el-tooltip>
      <svg-icon
        role="edit"
        v-permission
        icon="tableEdit"
        title="编辑"
        @click.native="edit"
      ></svg-icon>
      <svg-icon
        role="copy"
        v-permission
        icon="tableCopy"
        title="复制"
        @click.native="copy"
      ></svg-icon>
      <svg-icon
        role="internshipAgreement"
        v-permission
        icon="tablePact"
        title="实习生协议"
        @click.native="pdf"
      ></svg-icon>
      <svg-icon
        role="refusalJoin"
        v-permission
        icon="tableRejectRefuse"
        title="拒绝入职"
        @click.native="reject"
      ></svg-icon>
    </div>
    <div v-if="roleStatus === '已签回'">
      <svg-icon
        role="refusalJoin"
        v-permission
        icon="tableRejectRefuse"
        title="拒绝入职"
        @click.native="reject"
      ></svg-icon>
      <svg-icon
        role="edit"
        v-permission
        icon="tableEdit"
        title="编辑"
        @click.native="edit"
      ></svg-icon>
    </div>
    <div v-if="roleStatus === '新建'">
      <svg-icon
        role="update"
        v-permission
        icon="tableEdit"
        title="修改"
        @click.native="change"
      ></svg-icon>
      <svg-icon
        role="copy"
        v-permission
        icon="tableCopy"
        title="复制"
        @click.native="copy"
      ></svg-icon>
      <el-popconfirm title="确定删除？" @confirm="deleteItem">
        <svg-icon
          role="delete"
          v-permission
          slot="reference"
          icon="delete"
          title="删除"
        ></svg-icon>
      </el-popconfirm>
    </div>
    <div v-if="roleStatus === '已入职'">
      <svg-icon
        role="sendAttendanceEmail"
        v-permission
        icon="tableSendEmail"
        @click.native="sendAttendanceEmails"
        title="发送考勤邮件"
      ></svg-icon>
      <svg-icon
        role="internshipAgreement"
        v-permission
        icon="tablePact"
        title="实习生协议"
        @click.native="pdf"
      ></svg-icon>
      <svg-icon
        icon="tableSendResignationEmail"
        role="sendLeaveEmail"
        v-permission
        title="发送离职邮件"
        @click.native="sendAnExitEmail"
      ></svg-icon>
      <svg-icon
        role="edit"
        v-permission
        icon="tableEdit"
        title="编辑"
        @click.native="edit"
      ></svg-icon>
      <svg-icon
        icon="tableConfirmLeave"
        role="confirmLeaveEmail"
        v-permission
        title="确认离职"
        @click.native="ConfirmTheDeparture"
      ></svg-icon>
    </div>
    <div v-if="roleStatus === '已离职'">
      <el-tooltip
        class="item"
        effect="dark"
        content="下载实习证明"
        placement="top"
      >
        <el-button type="text" @click.native="downloadInternshipCertificate">
          <i class="svg-icon el-icon-download"></i>
        </el-button>
      </el-tooltip>
    </div>
    <div v-if="roleStatus === '离职申请中' || roleStatus === '离职待审批'">
      <el-tooltip
        v-show="roleStatus === '离职待审批'"
        class="item"
        effect="dark"
        content="下载实习证明"
        placement="top"
      >
        <el-button type="text" @click.native="downloadInternshipCertificate">
          <i class="svg-icon el-icon-download"></i>
        </el-button>
      </el-tooltip>
      <svg-icon
        icon="tableSendEmail"
        role="sendAttendanceEmail"
        v-permission
        @click.native="sendAttendanceEmails"
        title="发送考勤邮件"
      ></svg-icon>
      <svg-icon
        icon="tableConfirmLeave"
        role="confirmLeaveEmail"
        v-permission
        title="确认离职"
        @click.native="ConfirmTheDeparture"
      ></svg-icon>
      <svg-icon
        icon="tableWithdraw"
        role="cancelLeave"
        v-permission
        title="撤销离职"
        @click.native="CancelTheDeparture"
      ></svg-icon>
      <svg-icon
        role="edit"
        v-permission
        icon="tableEdit"
        title="编辑"
        @click.native="edit"
      ></svg-icon>
    </div>
    <div v-if="roleStatus === '待离职'">
      <svg-icon
        icon="tableSendEmail"
        role="sendAttendanceEmail"
        v-permission
        @click.native="sendAttendanceEmails"
        title="发送考勤邮件"
      ></svg-icon>
      <svg-icon
        icon="tableInternshipCertificate"
        role="internshipAgreement"
        v-permission
        title="实习证明"
        @click.native="PracticeProvesThat"
      ></svg-icon>
      <svg-icon
        icon="tableWithdraw"
        role="cancelLeave"
        v-permission
        title="撤销离职"
        @click.native="CancelTheDeparture"
      ></svg-icon>
      <svg-icon
        icon="tableConfirmLeave"
        role="confirmLeaveEmail"
        v-permission
        title="确认离职"
        @click.native="ConfirmTheDeparture"
      ></svg-icon>
      <svg-icon
        role="edit"
        v-permission
        icon="tableEdit"
        title="编辑"
        @click.native="edit"
      ></svg-icon>
    </div>
    <div v-if="roleStatus === '拒绝入职'">
      <svg-icon
        role="recall"
        v-permission
        icon="tableWithdraw"
        title="撤回"
        @click.native="toWithdraw"
      ></svg-icon>
      <!--      <svg-icon-->
      <!--        role="copy"-->
      <!--        v-permission-->
      <!--        icon="tableCopy"-->
      <!--        title="复制"-->
      <!--        @click.native="copy"-->
      <!--      ></svg-icon>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleButton',
  components: {},

  props: {
    roleStatus: {
      required: true
    }
  },
  methods: {
    /**
     * 删除 待招聘 实习生
     */
    deleteInRecruiting() {
      this.$emit('onClickButton', 'deleteInRecruiting')
    },
    edit() {
      this.$emit('onClickButton', 'edit')
    },
    /**
     * 下载实习证明
     */
    downloadInternshipCertificate() {
      this.$emit('onClickButton', 'downloadInternshipCertificate')
    },
    copy() {
      this.$emit('onClickButton', 'copy')
    },
    pdf() {
      this.$emit('onClickButton', 'pdf')
    },
    reject() {
      this.$emit('onClickButton', 'reject')
    },
    change() {
      this.$emit('onClickButton', 'change')
    },
    deleteItem() {
      this.$emit('onClickButton', 'deleteItem')
    },
    ConfirmTheDeparture() {
      this.$emit('onClickButton', 'ConfirmTheDeparture')
    },
    sendAttendanceEmails() {
      this.$emit('onClickButton', 'sendAttendanceEmails')
    },
    sendAnExitEmail() {
      this.$emit('onClickButton', 'sendAnExitEmail')
    },
    CancelTheDeparture() {
      this.$emit('onClickButton', 'CancelTheDeparture')
    },
    PracticeProvesThat() {
      this.$emit('onClickButton', 'PracticeProvesThat')
    },
    toWithdraw() {
      this.$emit('onClickButton', 'toWithdraw')
    }
  }
}
</script>

<style lang="scss" scoped>
.RoleButton {
  .svg-icon {
    font-size: 18px;
    margin-right: 15px;
    color: #ba9765;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $--color-button-primary;
    }
  }
}
</style>
