<template>
  <div class="statusDisplay">
    <span class="iconPont" :style="{ background: checkStatusClass() }"></span>
    <span>{{ status }}</span>
  </div>
</template>

<script>
export default {
  name: 'statusDisplay',
  props: {
    statusContent: {
      type: String
    }
  },
  watch: {
    statusContent: {
      handler(val) {
        this.status = val
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    checkStatusClass() {
      let colors = [
        {
          label: '新建',
          color: '#6EBAAF'
        },
        {
          label: '待招聘',
          color: '#DE3934'
        },
        {
          label: '已签回',
          color: '#2490E3'
        },
        {
          label: '已入职',
          color: '#BB9667'
        },
        {
          label: '离职申请中',
          color: '#8245B2'
        },
        {
          label: '离职待审批',
          color: '#8245B2'
        },
        {
          label: '待离职',
          color: '#E6A23C'
        },
        {
          label: '已离职',
          color: '#909399'
        },
        {
          label: '拒绝入职',
          color: '#DE3934'
        }
      ]
      let findCode = colors.find((item) => item.label === this.status)
      if (findCode) {
        return findCode.color
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.statusDisplay {
  width: 100%;
  height: 100%;
  .iconPont {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
</style>
