<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="title"
    width="900px"
    append-to-body
    destroy-on-close
    top="60px"
    custom-class="NewInternDialogDialog"
    :before-close="Cancel"
  >
    <div class="NewIntern">
      <div class="content">
        <el-form
          :inline="true"
          label-position="top"
          :model="formInline"
          :rules="rules"
          v-loading="loading"
          element-loading-text="请稍后.."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 1)"
          ref="NewInternFormRef"
          class="NewIntern-form"
        >
          <el-row :gutter="25">
            <el-col :lg="8">
              <el-form-item label="HFM Code" prop="hfmCode">
                <el-select
                  v-model="formInline.hfmCode"
                  clearable
                  :disabled="isHfmCodeDisabled"
                  @change="changeHfmCode"
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in hfmCodeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Title" prop="title">
                <el-input v-model="formInline.title" />
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Business Unit Name" prop="businessUnitId">
                <el-select
                  v-model="formInline.businessUnitId"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in businessUnitIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :lg="8">
              <el-form-item label="Line Manager LIID" prop="lineManagerLiid">
                <el-input
                  v-model="formInline.lineManagerLiid"
                  suffix-icon="el-icon-search"
                  @click.native="selectPerson('intern')"
                  placeholder="请选择"
                />
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item
                label="Assigment/project（Level2）"
                prop="assigmentProjectLevel2"
              >
                <el-select
                  v-model="formInline.assigmentProjectLevel2"
                  clearable
                  filterable
                  placeholder="请选择"
                  @change="checkOrganization(true)"
                >
                  <el-option
                    v-for="item in assigmentProjectLevel2List"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="!item.enable"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Line Manager Name" prop="lineManagerName">
                <el-input
                  v-model="formInline.lineManagerName"
                  disabled
                  placeholder="请选择"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :lg="8">
              <el-form-item
                label="Description（Level3）"
                prop="descriptionLevel3"
              >
                <el-select
                  v-model="formInline.descriptionLevel3"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in descriptionLevel3List"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="!item.enable"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Client">
                <el-input
                  v-model="formInline.client"
                  @click.native="openClient"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Department/Team" prop="departmentId">
                <el-select
                  v-model="formInline.departmentId"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in departmentIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :lg="8">
              <el-form-item label="Line Manager Email" prop="lineManagerEmail">
                <el-input
                  v-model="formInline.lineManagerEmail"
                  disabled
                  suffix-icon="el-icon-search"
                  placeholder="请选择"
                />
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Parent Function" prop="parentFunction">
                <el-select
                  v-model="formInline.parentFunction"
                  clearable
                  filterable
                  @change="changeParentFunction"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in parentFunctionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Child Function" prop="childFunction">
                <el-select
                  v-model="formInline.childFunction"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in childFunctionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :lg="8">
              <el-form-item
                label="Reason for position"
                prop="reasonForPosition"
              >
                <el-select
                  v-model="formInline.reasonForPosition"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in reasonForPositionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Leaver Name">
                <el-input
                  v-model="formInline.leaverName"
                  suffix-icon="el-icon-search"
                  @click.native="selectPerson('leaver')"
                  placeholder="Leaver Name"
                />
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="Legal Entity" prop="legalEntity">
                <el-select
                  v-model="formInline.legalEntity"
                  clearable
                  filterable
                  @change="getWorkLocation"
                  placeholder=" "
                >
                  <el-option
                    v-for="item in legalEntityList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="25">
            <el-col :lg="8">
              <el-form-item label="Work City" prop="workCity">
                <el-select
                  v-model="formInline.workCity"
                  clearable
                  placeholder="请选择"
                  @change="checkOrganization"
                >
                  <el-option
                    v-for="item in workCityList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8">
              <el-form-item label="CEO" prop="ceo">
                <el-input
                  v-model="formInline.ceoName"
                  suffix-icon="el-icon-search"
                  @click.native="selectCEO('CEO')"
                  placeholder="请选择"
                  readonly
                />
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>
        <el-row :gutter="12">
          <el-col :lg="24">
            <el-upload
              class="upload-demo"
              ref="upload"
              :on-change="onChange"
              :show-file-list="false"
              :auto-upload="false"
              action="#"
            >
              <svg-icon icon="upload" class="svgIconContent" />
              <div class="title_top">点击此区域上传附件或将文件拖至此处</div>
              <!--                <el-button slot="trigger" size="small" type="primary"-->
              <!--                  >Upload</el-button-->
            </el-upload>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <el-col :lg="8">
            <div class="uploadList">
              <div
                class="uploadList-item"
                v-for="(item, index) in uploadValList"
              >
                <div class="uploadList-item-top">
                  <div class="uploadList-item-left">
                    <!--                    <div class="uploadList-item-left-img">-->
                    <!--                      <img :src="countImgUrl(item.name)" alt="" />-->
                    <!--                    </div>-->
                    <div class="uploadList-item-left-title">
                      {{ item.name }}
                      <!--                      <br />-->
                      <!--                      {{ countKb(item.size) }}-->
                    </div>
                  </div>
                  <div
                    class="uploadList-item-right"
                    @click.stop="deleteFileList(item, index)"
                  >
                    <svg-icon icon="delete" style="margin-bottom: 5px" />
                  </div>
                </div>
                <!--              <el-progress :percentage="item.percentage"></el-progress>-->
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="bottomButton">
        <el-button @click="Cancel" :loading="loading">取消</el-button>
        <el-button @click="save" :loading="loading">保存</el-button>
        <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
      </div>

      <personnelSelectionDialog
        ref="personnelSelectionDialogRef"
        @onConfirm="onConfirm"
      />
    </div>
    <client-dialog ref="clientDialogRef" @onConfirm="clientDialogRefConfirm" />
  </el-dialog>
</template>

<script>
import pdf from '@/assets/img/Pdf.png'
import deleteImg from '@/assets/img/delete.png'
import jpg from '@/assets/img/jpg.png'
import json from '@/assets/img/JSON.png'
import png from '@/assets/img/png.png'
import word from '@/assets/img/word.png'
import js from '@/assets/img/js.png'
import file from '@/assets/img/file.png'
import {
  getBusinessUnitNameByHfmCode,
  getDepartmentList,
  getEntityHfmCode,
  getIntern,
  hrbpSave
} from '@/api/intern'
import { getChildFunction, getParentFunction } from '@/api/quto'
import { check, typeRange } from '@/utils/util'
import personnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import clientDialog from '@/views/intern/dialog/clientDialog'
import {
  getAssigmentProjectLevel2,
  getClient,
  getDescriptionLevel3,
  getLegalEntity,
  getWorkLocation
} from '@/api/personnelRequisition'
import { cityList, getSysOrgOrganizations } from "@/api/organization";
import isHfmCode from '@/views/intern/mixins/isHfmCode'

import { checkOrganization } from '@/api/organization'
export default {
  name: 'NewIntern',
  mixins: [isHfmCode],
  components: {
    personnelSelectionDialog,
    clientDialog
  },
  computed: {
    title() {
      let status = this.status
      if (status) {
        if (status === 'edit') {
          return '编辑实习生信息'
        } else {
          return '新增实习生'
        }
      } else {
        return '新增实习生'
      }
    }
  },
  data() {
    return {
      loading: false,
      formInline: {
        hfmCode: '',
        title: '',
        businessUnitId: '',
        assigmentProjectLevel2: '',
        lineManagerLiid: '',
        lineManagerName: '',
        departmentId: '',
        lineManagerEmail: '',
        childFunction: '',
        parentFunction: '',
        descriptionLevel3: '',
        reasonForPosition: '',
        leaverId: '',
        leaverName: '',
        client: '',
        workCity: '',
        id: '',
        ceoName: '',
        ceo: '',
        legalEntity: ''
      },
      status: '',
      deleteImg,
      businessUnitIdList: [],
      assigmentProjectLevel2List: [],
      descriptionLevel3List: [],
      departmentIdList: [],
      legalEntityList: [],
      parentFunctionList: [],
      workCityList: [],
      reasonForPositionList: [
        {
          label: 'New',
          value: 'New'
        },
        {
          label: 'Replacement',
          value: 'Replacement'
        }
      ],
      childFunctionList: [],
      tAOwnerList: [],
      dialogStatus: '',
      uploadValList: [],
      fileList: [],
      hfmCodeList: [],
      rules: {},
      dialogVisible: false,
    }
  },
  methods: {
    getCEO(){
      let params = {
        assigmentProjectId: this.formInline.assigmentProjectLevel2,
        hfm_code: this.formInline.hfmCode,
        legalEntity: this.formInline.legalEntity.split('_')[1],
        workLoaction: this.formInline.workCity
      }
      getSysOrgOrganizations(params).then(res=>{
        console.log(res);
        this.formInline.ceoName = res.data.ceoName
        this.formInline.ceo = res.data.ceo
      })
    },
    async checkOrganization(type = false) {
      if (type == true) {
        this.formInline.descriptionLevel3 = '';
        let params = {
          assigmentProjectLevel2: this.formInline.assigmentProjectLevel2
        }
        let DescriptionLevel3 = await getDescriptionLevel3(params);
        if (
          DescriptionLevel3 &&
          DescriptionLevel3.code &&
          DescriptionLevel3.code === 200
        ) {
          if (DescriptionLevel3.data) {
            this.descriptionLevel3List = DescriptionLevel3.data
          }
        }
      }

      if(!this.formInline.assigmentProjectLevel2 ||
        !this.formInline.hfmCode || !this.formInline.legalEntity
        || !this.formInline.workCity
      ) {
        return true
      } else {
        this.getCEO()
        let params = {
          assigmentProjectId: this.formInline.assigmentProjectLevel2,
          hfm_code: this.formInline.hfmCode,
          legalEntity: this.formInline.legalEntity.split('_')[1],
          workLoaction: this.formInline.workCity
        }
        let result = await checkOrganization(params).then(res => {
          let check = true;
          if(res && res.code == 200){
            res.data ? '' : (this.$message.error('所选机构已禁用！'),check = false)
          } else {
            check = false;
          }
          return check;
        })
        return result;
      }
    },
    async getFormLine(val = {}) {
      let HfmCode = await getEntityHfmCode()
      if (HfmCode && HfmCode.code === 200) {
        this.hfmCodeList = HfmCode.data
      }
      let NameByHfmCode = await getBusinessUnitNameByHfmCode(val)
      if (NameByHfmCode && NameByHfmCode.code === 200) {
        this.businessUnitIdList = NameByHfmCode.data
      }
      let AssigmentProjectLevel2 = await getAssigmentProjectLevel2()
      if (
        AssigmentProjectLevel2 &&
        AssigmentProjectLevel2.code &&
        AssigmentProjectLevel2.code === 200
      ) {
        if (AssigmentProjectLevel2.data) {
          this.assigmentProjectLevel2List = AssigmentProjectLevel2.data
        }
      }
      let DescriptionLevel3 = await getDescriptionLevel3()
      if (
        DescriptionLevel3 &&
        DescriptionLevel3.code &&
        DescriptionLevel3.code === 200
      ) {
        if (DescriptionLevel3.data) {
          this.descriptionLevel3List = DescriptionLevel3.data
        }
      }
      let ParentFunction = await getParentFunction()
      if (ParentFunction && ParentFunction.code === 200) {
        this.parentFunctionList = ParentFunction.data
      }
      let ChildFunction = await getChildFunction()
      if (ChildFunction && ChildFunction.code === 200) {
        this.childFunctionList = ChildFunction.data
      }
      let DepartmentList = await getDepartmentList()
      if (DepartmentList && DepartmentList.code === 200) {
        this.departmentIdList = DepartmentList.data
      }
      getLegalEntity().then((LegalEntity) => {
        if (LegalEntity.code && LegalEntity.code === 200) {
          if (LegalEntity.data) {
            this.legalEntityList = LegalEntity.data
          }
        }
      })
      this.getWorkLocation(true)
      // cityList().then((res) => {
      //   if (res && res.code === 200) {
      //     if (res.data) {
      //       this.workCityList = res.data
      //     }
      //   }
      // })
    },
    async changeParentFunction(val) {
      const data = {
        parentFunction: val
      }
      let ChildFunction = await getChildFunction(data)
      if (ChildFunction && ChildFunction.code === 200) {
        this.childFunctionList = ChildFunction.data
        this.formInline.childFunction = ''
      }
    },
    clientDialogRefConfirm(val) {
      if (val) {
        this.formInline.client = val.clientName
      }
    },
    openClient() {
      this.$refs.clientDialogRef.show(this.formInline.client)
    },
    async changeHfmCode(val) {
      this.getWorkLocation()
      let DepartmentList = await getDepartmentList({ hfmCode: val })
      if (DepartmentList && DepartmentList.code === 200) {
        this.departmentIdList = DepartmentList.data
      }
      let NameByHfmCode = await getBusinessUnitNameByHfmCode({ hfmCode: val })
      if (NameByHfmCode && NameByHfmCode.code === 200) {
        this.businessUnitIdList = NameByHfmCode.data
      }
    },
    onChange(val) {
      console.log(val)
      this.uploadValList.push(val.raw)
    },
    Cancel(val) {
      console.log(val)
      Object.keys(this.formInline).forEach((item) => {
        this.formInline[item] = ''
      })
      this.uploadValList = []
      this.dialogVisible = false
      if (check(val) === typeRange.string) {
        this.$emit('onClose')
      }
    },
    countKb(val) {
      return (val / 1024).toFixed(2) + ' kb'
    },
    deleteFileList(val, index) {
      // this.fileList.splice(index, 1)
      this.uploadValList.splice(index, 1)
    },
    countImgUrl(val) {
      if (val) {
        let name = val.split('.')[1]
        const obj = {
          pdf: {
            value: pdf
          },
          jpg: {
            value: jpg
          },
          json: {
            value: json
          },
          png: {
            value: png
          },
          word: {
            value: word
          },
          js: {
            value: js
          }
        }
        if (obj[name]) {
          return obj[name].value
        } else {
          return file
        }
      }
    },
    selectPerson(val) {
      this.dialogStatus = val
      this.$refs.personnelSelectionDialogRef.show({
        position: val,
        hfmCode: this.formInline.hfmCode
      })
    },
    selectCEO(val) {
      this.dialogStatus = val
      this.$refs.personnelSelectionDialogRef.show({
        type: val,
        hfmCode: this.formInline.hfmCode,
        position: 'Personnel',
        radio:true
      })
    },

    show(val = {}) {
      this.dialogVisible = true
      this.loading = true
      Object.keys(this.formInline).forEach((item) => {
        this.rules[item] = [
          {
            required: true,
            trigger: 'change',
            message: 'The field is required'
          }
        ]
      })

      val.hfmCode ? this.getFormLine({hfmCode: val.hfmCode}) : this.getFormLine()
      let status = val.status
      let id = val.id
      this.status = status
      if (status && (status === 'edit' || status === 'copy')) {
        if (id) {
          const data = {
            id
          }
          getIntern(data).then((res) => {
            if (res && res.code === 200) {
              if (res.data) {
                Object.keys(this.formInline).forEach((item) => {
                  this.formInline[item] = res.data[item] || ''
                })
                // this.fileList = res.data.files
                this.uploadValList = res.data.files
              }
            }
          })
        }
      }
      this.loading = false
    },
    save() {
      this.$refs.NewInternFormRef.validate(async (valid) => {
        if (valid) {
          let res = await this.checkOrganization()
          if(res) {
            this.loading = true
            let a = new FormData()
            Object.keys(this.formInline).forEach((item) => {
              a.append(item, this.formInline[item])
            })
            a.append('saveType', '1')
            let objArr = []
            this.uploadValList.forEach((item) => {
              if (check(item) === typeRange.object) {
                objArr.push(item)
              } else {
                a.append('files', item)
              }
            })
            a.append('file', JSON.stringify(objArr))
            // })
            let status = this.status
            if (status && status === 'copy') {
              a.set('id', '')
            }
            hrbpSave(a).then((res) => {
              this.loading = false
              if (res && res.code === 200) {
                this.$message.success('保存成功')
                this.Cancel('close')
              }
            })
          }
        } else {
          return undefined
        }
      })
    },
    onConfirm(val) {
      console.log(val)
      if (val && val.length) {
        if (this.dialogStatus === 'intern') {
          this.formInline.lineManagerLiid = val[0].lineManagerLiid
          this.formInline.lineManagerName = val[0].englishName
          this.formInline.lineManagerEmail = val[0].email
        } else if (this.dialogStatus === 'leaver') {
          this.formInline.leaverName = val[0].englishName
          this.formInline.leaverId = val[0].id
        }else if (this.dialogStatus === 'CEO') {
          this.formInline.ceoName = val[0].userName
          this.formInline.ceo = val[0].id
        }
      }
    },
    async getWorkLocation(val = false) {
      const data = {
        hfmCode: '',
        legalEntity: ''
      }
      let status = true
      let res = await this.checkOrganization()
      console.log('机构禁用',res)
      if(!res) return;
      Object.keys(data).forEach((item) => {
        if (this.formInline[item].length !== 0) {
          data[item] = this.formInline[item]
        } else {
          status = false
        }
      })
      if (status || val) {
        getWorkLocation(data).then((res) => {
          if (res && res.code === 200) {
            if (check(res.data) === typeRange.array) {
              if (res.data.length) {
                this.workCityList = res.data
                // this.formInline.workCity = res.data[0].value
              }
            }
          }
        })
      }
      this.getCEO()
    },
    async submit() {
      this.$refs.NewInternFormRef.validate(async (valid) => {
        if (valid) {
          let res = await this.checkOrganization()
          if(res) {
            this.loading = true
            let a = new FormData()
            Object.keys(this.formInline).forEach((item) => {
              a.append(item, this.formInline[item])
            })
            a.append('saveType', '2')
            // a.append('file', JSON.stringify(this.uploadValList))
            let objArr = []
            this.uploadValList.forEach((item) => {
              if (check(item) === typeRange.object) {
                objArr.push(item)
              } else {
                a.append('files', item)
              }
            })
            a.append('file', JSON.stringify(objArr))
            let status = this.status
            if (status && status === 'copy') {
              a.set('id', '')
            }
            hrbpSave(a).then((res) => {
              this.loading = false
              if (res && res.code === 200) {
                this.$message.success('提交成功')
                this.Cancel('close')
              }
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.NewInternDialogDialog {
  .content {
    height: calc(646px - 60px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 0 0 20px !important;
    .upload-demo {
      margin-top: 8px;
      img {
        width: 35px;
        height: 35px;
        margin-top: 15px;
      }
      :deep(.el-upload) {
        width: 99.5%;
        height: 92px;
        border: 2px dashed #c4c8cf;
        border-radius: 3px;
      }
      .title_top {
        font-size: 14px;
        font-weight: 400;
        color: #1b1d1e;
      }
      .title_bottom {
        font-size: 0.9em;
      }
    }
  }
  .intern-bran {
    position: relative;
    width: 110%;
    background: white;
    height: 60px;
    top: -13px;
    left: -14px;
    padding-left: 20px;
    padding-top: 20px;

    .top {
      font-size: 14px;
      color: darkgray;
    }

    .bottom {
      padding-top: 10px;
      font-weight: bold;
    }
  }

  .NewIntern {
    background: white;
    //margin-top: 70px;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    .NewIntern-form {
    }
    .uploadList {
      //height: 30px;
      //height: 150px;
      background: white;
      overflow-x: hidden;
      overflow-y: scroll;
      margin-top: 10px;
      .uploadList-item {
        border-bottom: 1px solid rgb(214, 214, 214);
        //padding-bottom: 5px;
        margin-bottom: 5px;
        .uploadList-item-top {
          //padding: 25px;
          display: flex;
          justify-content: space-between;
          .uploadList-item-left {
            display: flex;
            .uploadList-item-left-title {
              font-size: 14px;
              font-weight: 400;
              color: #1b1d1e;
              padding-bottom: 8px;
            }
          }

          .uploadList-item-right {
            font-size: 15px;
            cursor: pointer;
            //line-height: 40px;
          }
        }
      }
    }
    .bottomButton {
      text-align: right;
      padding-top: 10px;
      padding-right: 10px;
    }
  }
}
:deep(.NewInternDialogDialog.el-dialog) {
  overflow: hidden;
  .el-dialog__title {
    font-size: 21px;
    font-weight: 500;
    color: #303133;
  }
  .el-dialog__body {
    height: 646px;
    padding: 0 !important;
  }
}
.svgIconContent {
  font-size: 40px;
  margin-top: 13px;
}
</style>
