<template>
  <el-dialog
    title="Client"
    :visible.sync="dialogVisible"
    custom-class="clientDialog"
    width="50%"
    append-to-body
    destroy-on-close
    :before-close="close"
  >
    <el-form :inline="true" size="mini" :label-position="'top'">
      <el-form-item label="Client Name">
        <el-input v-model="clientName"></el-input>
      </el-form-item>
      <el-form-item label=".">
        <el-button type="primary" @click="queryApi">查询</el-button>
      </el-form-item>
    </el-form>

    <PocTable
      v-loading="loading"
      :data="tableData"
      ref="PocTableRef"
      :current-page.sync="pageConfig.pageNum"
      :total="total"
      :row-style="{ cursor: 'pointer' }"
      highlight-current-row
      @current-change="handleCurrentChange"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
    >
      <el-table-column
        v-for="item in columnConfig"
        v-if="item.visible"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.key === 'no' ? 60 : null"
        :min-width="item.width"
        show-overflow-tooltip
      ></el-table-column>
    </PocTable>
    <div style="text-align: right; padding: 10px 0 10px 0">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getClient } from '@/api/personnelRequisition'

export default {
  name: 'clientDialog',
  mixins: [$PCommon.TableMixin],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      columnConfig: [
        {
          key: 'clientName',
          name: 'Client Name',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        },
        {
          key: 'clientDescription',
          name: 'Client Description',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        },
        {
          key: 'type',
          name: 'Type',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: null, // 固定列的位置(left, right)
          width: null // 默认宽度，像素
        }
      ],
      tableData: [],
      clientName: '',
      total: 0,
      selectClientVal: '',
      selectVal: {}
    }
  },
  methods: {
    queryApi() {
      this.loading = true
      const data = {
        pageSize: this.pageConfig.pageSize,
        pageNum: this.pageConfig.pageNum,
        clientName: this.clientName
      }
      getClient(data).then((res) => {
        if (res && res.code === 200) {
          this.tableData = res.data.list
          let val = this.tableData.find(
            (item) => item.clientName === this.selectClientVal
          )
          if (val) {
            this.$refs.PocTableRef.setCurrentRow(val)
          }
          this.loading = false
          this.total = res.data.total
        }
      })
    },
    handleCurrentChange(val) {
      this.selectVal = val
    },
    close() {
      this.dialogVisible = false
    },
    onConfirm() {
      this.$emit('onConfirm', this.selectVal)
      this.close()
    },
    show(val) {
      this.selectClientVal = val
      this.queryApi()
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style>
.clientDialog {
  overflow: hidden !important;
}
</style>
